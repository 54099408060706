import React from 'react'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import MiniPsycF from '../boardPics/MiniPsycF.JPEG';
import MiniPsycB from '../boardPics/MiniPsycB.JPEG';

import MP1 from '../boardPics/MiniChannels.JPEG';
import MP2 from '../boardPics/MiniPsycChan.JPEG';
import MP3 from '../boardPics/MiniXCMiddy.JPEG';
import MP4 from '../boardPics/MiniXCMiddy.JPEG';


import {Link} from 'react-router-dom';

export default function App() { 
return ( 
	<div style={{ backgroundColor:'#1F1B24', margin:"0px" ,overflow:"hidden"}}>
		<div className='row' style={{}}> 
			<div className='col-lg-2 col-md-2 col-sm-12 col-12'></div>
			<div style={{margin:'0px 0px 0px 0px' }} className='col-lg-4 col-md-4 col-sm-6 col-12'>
				<div style={{ display: 'block', width:'100%', padding: '5%'}}>  
					<Carousel> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={MiniPsycF}
						alt="Image One"
					/> 
					</Carousel.Item> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={MiniPsycB}
						alt="Image Two"
					/> 
					</Carousel.Item> 
					
				</Carousel> 
				</div> 
			</div>
			<div className='col-lg-4 col-md-4 col-sm-6 col-12' style={{color:"white", textAlign: "right", paddingRight:"30px"}}>
				<h2 style={{padding:"15px 15px 0 0"}}>Mini Psyc</h2>
				<p style={{padding:"15px"}}> 
                    The Mini Psyc is the fun, small wave adaptation of the Psyc Rocket. It has double barrel channels throught the back half for speed generation. Overall a good time, can be ordered as a twin but the asym quad/twin is recommended
				</p>
				<em style={{padding:"15px", fontSize:'bold'}}>Dimensions</em>
				<p style={{padding:"15px"}}>
					Order short and wide
					<u>Pictured Below:</u><br/>
					<b>Green:  </b> 5'4" x 20" x 2 1/2"<br/>
					
				</p>
				<Link to="/custom"><button style={{width:"250px",height:"74px", color:"#1F1B24",marginBottom:"20px"}} type="button" class="">Order Custom</button></Link>
			</div>
		</div>
		<div style={{padding:"0px 30px 30px 30px", marginBottom:"30px"}}className='row'>
		<div className='col-lg-3 col-md-3 col-sm-3 col-12' style={{marginBottom:"30px"}}>
				<img src={MP2} alt="Logo" />
			</div>
			<div className='col-lg-5 col-md-5 col-sm-5 col-12' style={{marginBottom:"30px"}}>
				<img src={MP1} alt="Logo" />
			</div>
			<div className='col-lg-3 col-md-3 col-sm-3 col-12' style={{marginBottom:"30px"}}>
				<img src={MP3} alt="Logo" />
			</div>
		</div>
	</div>
); 
}
