import React, { useState, useEffect } from 'react';
import FilmsCarousel from './FilmsCarousel'; // New component for Carousel layout
import FilmsList from './FilmsList'; // New component for List layout

const Films = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ background: '#1F1B24', color: 'white' }}>
      {isMobile ? <FilmsList /> : <FilmsCarousel />}
    </div>
  );
};

export default Films;