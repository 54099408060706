// Home.js
import React from 'react';
import shapingPic from './boardPics/boardLine.jpg'

const Home = () => {
  return (
    <div className='' style={{padding:"2% 7% 7% 7%",color:"white",background:"#1F1B24"}}>
      <h2>Board Order Submitted</h2>
      <br/>
      <h4>Whats Next:</h4>
      <li>You'll receive an email with a confirmation or asking for clarifications</li>
      <li>We start working on your board</li>
      <li>You go surfing</li>
      <img 
        className="d-block w-100"
        src={shapingPic}
        alt="Image One"
        style={{padding:"2% 5% 0 5%"}}
        />
    </div>
  );
}

export default Home;
