// ImageWithCaption.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ImageWithCaption.css';

const ImageWithCaption = ({ initialImage, hoverImage, caption, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  const imageContent = (
    <>
      <img src={isHovered ? hoverImage : initialImage} alt="Image" />
      <div className="caption">{caption}</div>
    </>
  );

  return (
    <div className="image-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      {link ? (
        <Link to={link} className="image-link">
          {imageContent}
        </Link>
      ) : (
        imageContent
      )}
    </div>
  );
};

ImageWithCaption.propTypes = {
  initialImage: PropTypes.string.isRequired,
  hoverImage: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  link: PropTypes.string, // Optional link parameter
};

export default ImageWithCaption;
