import React from 'react'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import PsychRocketF from '../boardPics/PsycRocketFront.jpg';
import PsychRocketS from '../boardPics/PsycRocketS.JPG';
import PsychRocketB from '../boardPics/PsycRocketBack.jpg';
import PR1 from '../boardPics/PR1.JPG'
import PR2 from '../boardPics/PR2.JPG'
import { BrowserRouter as Router, Route,Routes, Switch, Link } from 'react-router-dom';

export default function App() { 
return ( 
	<div style={{ backgroundColor:'#1F1B24', margin:"0px" ,overflow:"hidden"}}>
		<div className='row' style={{}}> 
			<div className='col-lg-2 col-md-2 col-sm-12 col-12'></div>
			<div style={{margin:'0px 0px 0px 0px' }} className='col-lg-4 col-md-4 col-sm-6 col-12'>
				<div style={{ display: 'block', width:'100%', padding: '5%'}}>  
				<Carousel> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={PsychRocketF}
						alt="Image One"
					/> 
					</Carousel.Item> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={PsychRocketB}
						alt="Image Two"
					/> 
					</Carousel.Item> 
					<Carousel.Item> 
					<img 
						className="d-block w-100"
						src={PsychRocketS}
						alt="Image Two"
					/> 
					</Carousel.Item> 
				</Carousel> 
				</div> 
			</div>
			<div className='col-lg-4 col-md-4 col-sm-6 col-12' style={{color:"white", textAlign: "right", paddingRight:"30px"}}>
				<h2 style={{padding:"15px 15px 0 0"}}>Psyc Rocket</h2>
				<p style={{padding:"15px"}}> 
					This is the flagship PBH asymmetric model, a quick daily driver that can be taken up to well-overhead.
                    With a longer rail line and twin fins under your toes, you can draw out toe-side turns and snap back with a shorter quad pivot point on your heels.
                    The Psyc Rocket feels like a differnent baord going FS or BS, both catering to the rider's stance.
				</p>
				<em style={{padding:"15px", fontSize:'bold'}}>Dimensions</em>
				<p style={{padding:"15px"}}>
					Order the same dims as your favorite daily driver 
				</p>
				<u>Pictured Below:</u><br/><br/>
					<b>Orange:  </b> 5'10" x 19 1/2" x 2 5/8"<br/><br/>
				<Link to="/custom"><button style={{width:"250px",height:"74px", color:"#1F1B24",marginBottom:"20px"}} type="button" class="">Order Custom</button></Link>
			</div>
		</div>
		<div style={{padding:"0px 30px 30px 30px", marginBottom:"30px"}} className='row'>
			<div className='col-lg-4 col-md-4 col-sm-4 col-12' style={{marginBottom:"30px"}}>
				<img src={PR1} alt="Logo" />
			</div>
			<div style={{}}className='col-lg-8 col-md-8 col-sm-8 col-12'>
				<img src={PR2} alt="Logo" />
			</div>
		</div>
	</div>
); 
}

