// App.js
import React from 'react';
import { BrowserRouter as Router, Route,Routes, Link } from 'react-router-dom';
import Home from './Home';
import Boards from './Boards';
import Films from './Films';
import About from './About';
import Merch from './Merch'
import './App.css'; // You can create this file for styling
import Custom from './Custom';
import PsycRocket from './boardModels/Board_PR';
import PBHStep from './boardModels/PBHStep';
import DaFerg from './boardModels/DaFerg';
import Middy from './boardModels/Mid';
import DaLawg from './boardModels/DaLawg';
import MiniPsyc from './boardModels/MiniPsyc';
import FormSubmitted from './FormSubmitted'

function App() {
  return (
    
    <Router>
      <link rel="stylesheet"href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" ></link>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"/>

      <div>
        <nav style={{margin:"0px 0px 0px 0px",padding:"0px 20% 0px 20%", textDecoration:"none"}}className="navbar">
          <a><Link to="/">Home</Link></a>
          <a><Link to="/boards">Boards</Link></a>
          <a><Link to="/films">Films</Link></a>
          <a><Link to="/merch">Merch</Link></a>
        </nav>
        
 
          <div className="app">
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/boards" element={<Boards />}></Route>
              <Route path="/films" element={<Films />}></Route>
              <Route path="/merch" element={<Merch />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/custom" element={<Custom />}></Route>

              <Route path="/formSubmitted" element={<FormSubmitted />}></Route>

              <Route path="/boards/psycrocket" element={<PsycRocket />}></Route>
              <Route path="/boards/pbhstep" element={<PBHStep />}></Route>
              <Route path="/boards/daferg" element={<DaFerg />}></Route>
              <Route path="/boards/middy" element={<Middy />}></Route>
              <Route path="/boards/dalawg" element={<DaLawg />}></Route>
              <Route path="/boards/minipsyc" element={<MiniPsyc />}></Route>
            </Routes  >
          </div>
       
      </div>
    </Router>
  );
}

export default App;
