import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './form.css';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const form = useRef();
  const navigate = useNavigate()
  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs
      .sendForm('service_184vhu7', 'template_wm5udir', form.current, {
        publicKey: 'AqmhyKD0KYNYBbhJJ',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          navigate('/formSubmitted')
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      
  };

  return ( 
    <form style={{marginTop:"30px"}} ref={form} onSubmit={sendEmail}>
      <div className='inputContainer'>
        <div> <label>Name</label> </div>
        <input required type="text" name="user_name" />
      </div>

      <div className='inputContainer' >
        <div> <label>Email</label> </div>
        <input required type="email" name="user_email" />
      </div>

      <div className='inputContainer'>
        <div> <label>Phone</label> </div>
        <input type="phone" name="user_phone" />
      </div>
      
      <div className='inputContainer'>
        <div> <label>Board Model</label> </div>
        <p>Either one of ours or a custom design</p>
        <input required type="model" name="user_model" />
      </div>

      <div className='inputContainer'>
        <div> <label>Prefered Dimensions</label> </div>
        <p>If you're unsure, put a length or ask for a email consultation</p>
        <input type="dims" name="user_dims" />
      </div>

      <div className='inputContainer'>
        <div> <label>Any other information you want us to know</label> </div>
        <p>Get creative here or we'll take creative freedom</p>
        <textarea name="message" />
      </div>
      <input style={{width:"30%", marginTop:"40px"}} type="submit" value="Send" />
      
    </form>
    
  );
};

export default ContactUs;