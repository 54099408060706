// Home.js
import React, { useState , useEffect} from 'react';
import {Link } from 'react-router-dom';
import './homepage.css'

const Home = () => {
  return (
    <div className='home-background'  >
      <Link to="/custom"><button style={{width:"200px",height:"64px",margin:"20px", color:"#1F1B24", border: ".25rem solid", borderRadius:"4px"}} type="button" class="">Order Custom</button></Link>
      <Link to="/boards"><button style={{width:"200px",height:"64px",margin:"20px",color:"#1F1B24",border: ".25rem solid", borderRadius:"4px"}} type="button" class="">Board Models</button></Link>
    </div>
  );
}

const MobileHomePage = () => {
  return (
    <div  className='mobile-background'   >
      <div style={{padding:"0px 10px 80% 10px"}}>
        <div>
          <Link to="/custom"><button style={{width:"150px",height:"64px",margin:"10px", color:"#1F1B24", border: ".25rem solid", borderRadius:"4px"}} type="button" class="">Order Custom</button></Link>
        
        </div>
        <div>
          <Link to="/boards"><button style={{width:"150px",height:"64px",margin:"10px",color:"#1F1B24",border: ".25rem solid", borderRadius:"4px"}} type="button" class="">Board Models</button></Link>
  
        </div>
      
      </div>
      <div style={{}}>
        
      </div>
    </div>
  );
}

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
     setIsMobile(window.innerWidth <= 768);
   };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      {isMobile ? (
        <MobileHomePage />  // Your mobile-specific component 
      ) : (
        <Home /> // Your regular component
      )}
    </div>
  );
}

export default HomePage;