import React from 'react'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import LawgF from '../boardPics/LawgF.jpg';
import LawgB from '../boardPics/LawgB.jpg';
import LawgS from '../boardPics/dalawgS.jpg';
import DaLawgBot from '../boardPics/DaLawgBot.jpg'
import DaLawgT from '../boardPics/DaLawgT.jpg'

import {Link} from 'react-router-dom';

export default function App() { 
return ( 
	<div style={{ backgroundColor:'#1F1B24', margin:"0px" ,overflow:"hidden"}}>
		<div className='row' style={{}}> 
			<div className='col-lg-2 col-md-2 col-sm-12 col-12'></div>
			<div style={{margin:'0px 0px 0px 0px' }} className='col-lg-4 col-md-4 col-sm-6 col-12'>
				<div style={{ display: 'block', width:'100%', padding: '5%'}}>  
				<Carousel> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={LawgF}
						alt="Image One"
					/> 
					</Carousel.Item> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={LawgB}
						alt="Image Two"
					/> 
					</Carousel.Item> 
                    <Carousel.Item > 
					<img 
						className="d-block w-100"
						src={LawgS}
						alt="Image Two"
					/> 
					</Carousel.Item> 
				</Carousel> 
				</div> 
			</div>
			<div className='col-lg-4 col-md-4 col-sm-6 col-12' style={{color:"white", textAlign: "right", paddingRight:"30px"}}>
				<h2 style={{padding:"15px 15px 0 0"}}>Da Lawg</h2>
				<p style={{padding:"15px"}}> 
					Da Lawg is a tried and true log, it's got a classic nose spoon for noseriding. You can order with any tail shape you're looking for, the squash it pictured below.
				</p>
				<em style={{padding:"15px", fontSize:'bold'}}>Dimensions</em>
				<p style={{padding:"15px"}}>
					8'6 - 11' <br/><br/>
					<u>Pictured Below:</u><br/><br/>
					<b>Hendrix Bottom:  </b> 9'0  x 23 1/4" x 3 1/4"<br/>
					
				</p>
				<Link to="/custom"><button style={{width:"250px",height:"74px", color:"#1F1B24",marginBottom:"20px"}} type="button" class="">Order Custom</button></Link>
			</div>
		</div>
		<div style={{padding:"0px 30px 30px 30px", marginBottom:"30px"}} className='row'>
			<div className='col-lg-6 col-md-6 col-sm-6 col-12' style={{marginBottom:"30px"}}>
				<img src={DaLawgBot} alt="Logo" />
			</div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-12' style={{marginBottom:"30px"}}>
				<img src={DaLawgT} alt="Logo" />
			</div>
		</div>
	</div>
); 
}
