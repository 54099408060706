import React from 'react'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import PBHStepF from '../boardPics/PBHStepFolder/PBHStepF.jpg';
import PBHStepB from '../boardPics/PBHStepFolder/PBHStepB.jpg';
import PBHStepS from '../boardPics/PBHStepFolder/PBHStepS.jpg';

import PR1 from '../boardPics/PBHStepFolder/BenRippah.JPG';
import PR2 from '../boardPics/PBHStepFolder/connersunset.jpg';
import PR3 from '../boardPics/PBHStepFolder/RockPic.JPG';
import PR4 from '../boardPics/PBHStepFolder/Palapa.jpg';

import {Link} from 'react-router-dom';

export default function App() { 
return ( 
	<div style={{ backgroundColor:'#1F1B24', margin:"0px" ,overflow:"hidden"}}>
		<div className='row' style={{}}> 
			<div className='col-lg-2 col-md-2 col-sm-12 col-12'></div>
			<div style={{margin:'0px 0px 0px 0px' }} className='col-lg-4 col-md-4 col-sm-6 col-12'>
				<div style={{ display: 'block', width:'100%', padding: '5%'}}>  
					<Carousel> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={PBHStepF}
						alt="Image One"
					/> 
					</Carousel.Item> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={PBHStepB}
						alt="Image Two"
					/> 
					</Carousel.Item> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={PBHStepS}
						alt="Image Two"
					/> 
					</Carousel.Item> 
					
				</Carousel> 
				</div> 
			</div>
			<div className='col-lg-4 col-md-4 col-sm-6 col-12' style={{color:"white", textAlign: "right", paddingRight:"30px"}}>
				<h2 style={{padding:"15px 15px 0 0"}}>PBH Step</h2>
				<p style={{padding:"15px"}}> 
					 The PBH step is our Step Up model. It's got widepoint forward a rounded pintain, and enough foam to help you charge in some solid surf. The PBH step is a go to for anything 6ft and above.
				</p>
				<em style={{padding:"15px", fontSize:'bold'}}>Dimensions</em>
				<p style={{padding:"15px"}}>
					Order a few inches taller and a bit narrower than your daily driver <br/><br/>
					<u>Pictured Below:</u><br/><br/>
					<b>Black Bottom:  </b> 5'10" x 19 1/2" x 2 5/8"<br/>
					<b>All White:</b> 6'0" x 19" x 2 5/8"
				</p>
				<Link to="/custom"><button style={{width:"250px",height:"74px", color:"#1F1B24",marginBottom:"20px"}} type="button" class="">Order Custom</button></Link>
			</div>
		</div>
		<div style={{padding:"0px 30px 30px 30px", marginBottom:"30px"}}className='row'>
		<div className='col-lg-3 col-md-3 col-sm-3 col-12' style={{marginBottom:"30px"}}>
				<img src={PR2} alt="Logo" />
			</div>
			<div className='col-lg-5 col-md-5 col-sm-5 col-12' style={{marginBottom:"30px"}}>
				<img src={PR1} alt="Logo" />
			</div>
			<div className='col-lg-3 col-md-3 col-sm-3 col-12' style={{marginBottom:"30px"}}>
				<img src={PR4} alt="Logo" />
			</div>
		</div>
	</div>
); 
}
