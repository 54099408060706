import React from 'react';
import MerchListing from './MerchListing.js'; // Assuming your components are in a file 

// ... your other page components

function MerchPage() {
  return (
    <div>
      <h1>Our Merch</h1>
     <MerchListing />
    </div>
  );
}

export default MerchPage