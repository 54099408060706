import React from 'react'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import CMidF from '../boardPics/CMidF.jpg';
import CMidB from '../boardPics/CMidB.jpg';
import Middies from '../boardPics/2Middys.jpg';
import Channels from '../boardPics/Channels.jpg';
import MidNose from '../boardPics/ChanneledMidNose.jpg'
import {Link} from 'react-router-dom';

export default function App() { 
return ( 
	<div style={{ backgroundColor:'#1F1B24', margin:"0px" ,overflow:"hidden"}}>
		<div className='row' style={{}}> 
			<div className='col-lg-2 col-md-2 col-sm-12 col-12'></div>
			<div style={{margin:'0px 0px 0px 0px' }} className='col-lg-4 col-md-4 col-sm-6 col-12'>
				<div style={{ display: 'block', width:'100%', padding: '5%'}}>  
				<Carousel> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={CMidF}
						alt="Image One"
					/> 
					</Carousel.Item> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={CMidB}
						alt="Image Two"
					/> 
					</Carousel.Item> 
					
				</Carousel> 
				</div> 
			</div>
			<div className='col-lg-4 col-md-4 col-sm-6 col-12' style={{color:"white", textAlign: "right", paddingRight:"30px"}}>
				<h2 style={{padding:"15px 15px 0 0"}}>Channeled Middy</h2>
				<p style={{padding:"15px"}}> 
					The Channeled Middy is a good time type of midlength. It's fun, speedy, and unique. It's avalible in twin, 2+1, or single. 
				</p>
				<em style={{padding:"15px", fontSize:'bold'}}>Dimensions</em>
				<p style={{padding:"15px"}}>
					6' 6" - 7' 10"  <br/><br/>
					<u>Pictured Below:</u><br/><br/>
					<b>Blue One:  </b> 6'10" x 20 7/8" x 2 3/4"<br/>
					
				</p>
				<Link to="/custom"><button style={{width:"250px",height:"74px", color:"#1F1B24",marginBottom:"20px"}} type="button" class="">Order Custom</button></Link>
			</div>
		</div>
		<div style={{padding:"0px 30px 30px 30px", marginBottom:"30px"}}className='row'>
			<div className='col-lg-4 col-md-4 col-sm-4 col-12' style={{marginBottom:"30px"}}>
				<img src={Middies} alt="Logo" />
			</div>
            <div className='col-lg-4 col-md-4 col-sm-4 col-12' style={{marginBottom:"30px"}}>
				<img src={MidNose} alt="Logo" />
			</div>
            <div className='col-lg-4 col-md-4 col-sm-4 col-12' style={{marginBottom:"30px"}}>
				<img src={Channels} alt="Logo" />
			</div>
		</div>
	</div>
); 
}
