import React from 'react'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import DaFergF from '../boardPics/DaFergF.jpg';
import DaFergB from '../boardPics/DaFergB.jpg';
import DaFergTemp from '../boardPics/daFergBlank.jpg';


import {Link} from 'react-router-dom';

export default function App() { 
	return ( 
		<div style={{ backgroundColor:'#1F1B24', margin:"0px" ,overflow:"hidden"}}>
			<div className='row' style={{}}> 
				<div className='col-lg-2 col-md-2 col-sm-12 col-12'></div>
				<div style={{margin:'0px 0px 0px 0px' }} className='col-lg-4 col-md-4 col-sm-6 col-12'>
					<div style={{ display: 'block', width:'100%', padding: '5%'}}>  
				<Carousel> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={DaFergF}
						alt="Image One"
					/> 
					</Carousel.Item> 
					<Carousel.Item > 
					<img 
						className="d-block w-100"
						src={DaFergB}
						alt="Image Two"
					/> 
					</Carousel.Item> 
					
				</Carousel> 
				</div> 
			</div>
			<div className='col-lg-4 col-md-4 col-sm-6 col-12' style={{color:"white", textAlign: "right", paddingRight:"30px"}}>
				<h2 style={{padding:"15px 15px 0 0"}}>Da Ferg</h2>
				<p style={{padding:"15px"}}> 
					Da ferg's a bit of an experimental small wave board. 
                    Things it is:
                    <li>Small</li>
                    <li>Wide</li>
                    <li>Fun</li><br/>
				</p>
				<em style={{padding:"15px", fontSize:'bold'}}>Dimensions</em>
				<p style={{padding:"15px"}}>
					Order short and wide<br/><br/>
					<u>Pictured Below:</u><br/><br/>
					<b>Greenish Bottom:  </b> 5'4" x 20 1/4" x 2 3/4"<br/>
					
				</p>
				<Link to="/custom"><button style={{width:"250px",height:"74px", color:"#1F1B24",marginBottom:"20px"}} type="button" class="">Order Custom</button></Link>
			</div>
		</div>
		<div style={{padding:"0px 30px 30px 30px"}}className='row'>
			<div className='col-12'>
				<img src={DaFergTemp} alt="Logo" />
			</div>
		</div>
	</div>
); 
}
