// Home.js
import React from 'react';
import ContactUs from './SendEmail'
import stringerPic from './boardPics/Reavill_IndoorshallowDOF.jpg';
import shapingPic from './boardPics/blanksInCar.JPG'
const Custom = () => {
  return (
    <div>
      <div style={{margin:"0"}} className='row'>
        <div style={{margin:"0", padding:"1% 3% 1% 3%"}}className='col-6'> 
          <h2>Custom Order Form</h2>
          <h5>If you're interested in a board please fill this out and a shaper will contact you shortly</h5>
          <ContactUs/>
        </div> 
        <div style={{margin:"0",  background: ''}} className='col-6'>
          <img 
            className="d-block w-100"
						src={stringerPic}
						alt="Image One"
            style={{padding:"2% 5% 0 5%"}}
					/> 
          <img 
						className="d-block w-100"
						src={shapingPic}
						alt="Image One"
            style={{padding:"2% 5% 0 5%"}}
					/>
        </div>
      </div>
    </div>
  );
}

export default Custom;
