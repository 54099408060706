
const merchandiseData = [
    {
      title: "Logo T-Shirt \n coming soon",
      price: 20.00,
      imageUrl: "https://path/to/logo-tshirt.jpg" 
    },
    {
      title: "Coffee Mug \n coming soon",
      price: 15.00,
      imageUrl: "https://path/to/coffee-mug.jpg" 
    },
    // ... more items
  ];


function MerchListing() {
    return (
      <div className="merch-list">
        {merchandiseData.map((item) => (
          <MerchItem key={item.title} {...item} />
        ))}
      </div>
    );
  }

  function MerchItem({ title, price, imageUrl }) {
    return (
      <div className="merch-item">
        <img src={imageUrl} alt={title} />
        <h3>{title}</h3>
        <p className="price">${price.toFixed(2)}</p>
      </div>
    );
  }

  export default MerchListing