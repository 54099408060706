// Home.js
import React from 'react';
import ImageWithCaption from './ImageWithCaption'
import PsychRocketF from './boardPics/PsycRocketFront.jpg';
import PsychRocketB from './boardPics/PsycRocketBack.jpg';
import PBHStepF from './boardPics/PBHStepFolder/PBHStepF.jpg';
import PBHStepB from './boardPics/PBHStepFolder/PBHStepB.jpg';
import DaFergF from './boardPics/DaFergF.jpg';
import DaFergB from './boardPics/DaFergB.jpg';
import CMidF from './boardPics/CMidF.jpg';
import CMidB from './boardPics/CMidB.jpg';
import LawgF from './boardPics/LawgF.jpg';
import LawgB from './boardPics/LawgB.jpg';
import MiniPsycF from './boardPics/MiniPsycF.JPEG';
import MiniPsycB from './boardPics/MiniPsycB.JPEG';

const Boards = () => {
  return (
    <div style={{background:'#1F1B24',margin:"0px 0px 0px 0px",height:"auto", padding:"0 13% 0 13%"}} className="">
      
      <div className="row">
        <div className="col-lg-4 col-md-4">
          <ImageWithCaption
            initialImage={PsychRocketF}
            hoverImage={PsychRocketB}
            caption="Psych Rocket"
            link="psycrocket"  
          />  
        </div>

        <div className="col-lg-4 col-md-4">
          <ImageWithCaption
              initialImage={PBHStepF}
              hoverImage={PBHStepB}
              caption="PBH Step"
              link="pbhstep"  
            />
        </div>

        <div className="col-lg-4 col-md-4">
          <ImageWithCaption
              initialImage={DaFergF}
              hoverImage={DaFergB}
              caption="Da Ferg"
              link="daferg"
            />
        </div>

      </div>
      <div className="row">
        <div className="col-lg-4 col-md-4">
          <ImageWithCaption
            initialImage={CMidF}
            hoverImage={CMidB}
            caption="Channeled Middy"
            link="middy"
          />  
        </div>


        <div className="col-lg-4 col-md-4">
          <ImageWithCaption
              initialImage={LawgF}
              hoverImage={LawgB}
              caption="Da Lawg"
              link="dalawg"
            />
        </div>

        <div className="col-lg-4 col-md-4">
          <ImageWithCaption
              initialImage={MiniPsycF}
              hoverImage={MiniPsycB}
              caption="Mini Psyc"
              link="minipsyc"
            />
        </div>
      </div>
    </div>
  );
}

export default Boards;
