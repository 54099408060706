// Home.js
import React from 'react';
import YoutubeEmbed from './YoutubeEmbed';

import Carousel from 'react-bootstrap/Carousel';
const Films = () => {
  return (
    <div style={{background:'#1F1B24', color:"white"}} >
      <Carousel> 
        <Carousel.Item interval={4000}> 
          <div style={{margin:"30px"}} >
            <div style={{margin:"0 25% 0 25%", background:'#1F1B24'}}>
              <YoutubeEmbed embedId="eXubgav9Gmc" />
              <h2>Trigo</h2>
              <p>a film by Jake Stone</p>
            </div>
            
          </div>
        </Carousel.Item> 
        <Carousel.Item interval={4000}> 
          <div style={{margin:"30px"}} >
            <div style={{margin:"0 25% 0 25%", background:'#1F1B24'}}>
              <YoutubeEmbed embedId="vhiXnycsI2A" />
              <h2>Centrally Located</h2>
              <p>a film by Ben Carrasco</p>
            </div>
            
          </div>
        </Carousel.Item> 
      </Carousel> 
    </div>
  );
}

export default Films;
