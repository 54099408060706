import React from 'react';
import YoutubeEmbed from './YoutubeEmbed';

const FilmsList = () => {
  return (
    <div>
      {/* Map through your film data (currently hardcoded, ideally from state or props) */}
      <div style={{ margin: '30px' }}>
        <YoutubeEmbed embedId="eXubgav9Gmc" />
        <h2>Trigo</h2>
        <p>a film by Jake Stone</p>
      </div>
      <div style={{ margin: '30px' }}>
        <YoutubeEmbed embedId="vhiXnycsI2A" />
        <h2>Centrally Located</h2>
        <p>a film by Ben Carrasco</p>
      </div>
    </div>
  );
};

export default FilmsList;